import { RADIUS } from "./enums";
import { coordinatesToPosition, distance, tween } from "./utils";

const DISTANCE_TRHRESHOLD = 5;

export function updateFocus(
  focus,
  camera,
  { options, previousFocus, shouldUnlockAfterFocus, lock, unlock, saveFocus },
) {
  const {
    cameraDistanceRadiusScale,
    focusAnimationDuration,
    focusDistanceRadiusScale,
    focusEasingFunction,
    enableMarkerZoom,
  } = options;

  if (focus) {
    const from = [camera.position.x, camera.position.y, camera.position.z];
    const to = coordinatesToPosition(
      focus,
      RADIUS *
        (enableMarkerZoom
          ? focusDistanceRadiusScale
          : cameraDistanceRadiusScale),
    );
    if (distance(from, to) > DISTANCE_TRHRESHOLD) {
      saveFocus(focus);

      lock();

      return tween({
        from,
        to,
        animationDuration: focusAnimationDuration,
        easingFunction: focusEasingFunction,
        onUpdate: () => {
          const [x, y, z] = from;
          camera.position.set(x, y, z);
        },
        onEnd: () => {
          if (shouldUnlockAfterFocus) {
            unlock();
          }
        },
      });
    }
  } else if (previousFocus) {
    const from = [camera.position.x, camera.position.y, camera.position.z];
    const to = coordinatesToPosition(
      previousFocus,
      RADIUS * cameraDistanceRadiusScale,
    );

    lock();

    return tween({
      from,
      to,
      animationDuration: focusAnimationDuration,
      easingFunction: focusEasingFunction,
      onUpdate: () => {
        const [x, y, z] = from;
        camera.position.set(x, y, z);
      },
      onEnd: () => {
        saveFocus(null);
        unlock();
      },
    });
  }
}
